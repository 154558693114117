import type { ComboboxData } from "@mantine/core";

export const subscriptionIcons = [
	"telegram",
	"netflix",
	"jetbrains",
	"github",
	"youtube",
	"house",
	"headspace",
	"godaddy",
	"moovit",
	"tooth",
	"car",
	"eye",
	"heart",
	"city",
	"plane-departure",
	"plane-arrival",
	"car-burst",
	"dumbbell",
	"bolt",
	"people-group",
	"sack-dollar",
	"yin-yang",
	"cat",
	"kit-medical",
	"proton-mail",
	"google",
	"spotify",
	"landmark",
	"faucet-drip",
	"fire-flame-simple",
	"scissors",
	"receipt",
	"globe",
	"building",
	"phone",
	"credit-card",
	"cake-candles",
	"wiper-wash",
	"bed",
	"device-mobile",
	"theater",
] as const;
export type SubscriptionIconModel = (typeof subscriptionIcons)[number];

export const subscriptionIconToLabel = {
	telegram: "Telegram",
	netflix: "Netflix",
	jetbrains: "JetBrains",
	github: "GitHub",
	youtube: "YouTube",
	house: "Home",
	headspace: "HeadSpace",
	godaddy: "GoDaddy",
	moovit: "Moovit",
	tooth: "Dental",
	car: "Car",
	eye: "Eye",
	heart: "Heart",
	city: "Buildings",
	"plane-departure": "Plane Departure",
	"plane-arrival": "Plane Arrival",
	"car-burst": "Car Crash",
	dumbbell: "Barbell",
	bolt: "Bolt",
	"people-group": "Users",
	"sack-dollar": "Moneybag",
	"yin-yang": "Yin Yang",
	cat: "Cat",
	"kit-medical": "Medical Cross",
	"proton-mail": "Proton Mail",
	google: "Google",
	spotify: "Spotify",
	landmark: "Building Bank",
	"faucet-drip": "Bucket Droplet",
	"fire-flame-simple": "Flame",
	scissors: "Scissors",
	receipt: "Receipt",
	globe: "World",
	building: "Building",
	phone: "Phone",
	"credit-card": "Credit Card",
	"cake-candles": "Cake",
	"wiper-wash": "Wiper Wash",
	bed: "Bed",
	"device-mobile": "Device Mobile",
	theater: "Theater",
} as const satisfies Record<SubscriptionIconModel, string>;

export const subscriptionIconsComboboxData: ComboboxData = subscriptionIcons
	.map((icon) => ({
		value: icon,
		label: subscriptionIconToLabel[icon],
	}))
	.toSorted((a, b) => a.label.localeCompare(b.label));
